exports.SubHeaderFade = delay => {
  return {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay ? delay : 0.66,
        duration: 1,
        ease: [0.215, 0.61, 0.355, 1],
      },
    },
    initial: {
      opacity: 0,
      y: 20,
      transition: {
        when: "afterChildren",
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        when: "beforeChildren",
      },
    },
  }
}

exports.SubHeaderFadeReverse = delay => {
  return {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay ? delay : 0.66,
        duration: 1,
        ease: [0.215, 0.61, 0.355, 1],
      },
    },
    initial: {
      opacity: 0,
      y: -10,
      transition: {
        when: "afterChildren",
      },
    },
  }
}

exports.HeaderFade = delay => {
  return {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay ? delay : 0.66,
        duration: 1.4,
        ease: [0.215, 0.61, 0.355, 1],
      },
    },
    initial: {
      opacity: 0,
      y: 40,
      transition: {
        when: "afterChildren",
      },
    },
  }
}
