export default {
  text: "#1a1a1a",
  background: "#fff",
  modes: {
    dark: {
      text: "#fff",
      background: "#1a1a1a",
    },
  },
}
