import React, { useEffect } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Flex, Heading } from "components"
import { SubHeaderFadeReverse, HeaderFade } from "animations"

const topSpace = "80px"

const NavItem = ({ onClick, label, to, index }) => (
  <Heading
    data-component-id="navItem"
    as="div"
    size={100}
    position="relative"
    mr={["layout.2", "layout.3", "layout.4"]}
    css={{ ":last-of-type": { marginRight: 0 } }}
    onClick={() => {
      if (onClick) {
        onClick()
      }
    }}
  >
    <motion.div
      variants={SubHeaderFadeReverse(0.33 + index * 0.11)}
      initial="initial"
      animate="visible"
      exit="exit"
      key={"headerElementWork"}
    >
      <Link
        data-component-id="navItem.link"
        to={to}
        activeClassName="currentPage"
        css={{
          "&.currentPage": {
            "+ span": {
              opacity: 1,
            },
          },
        }}
      >
        {label}
      </Link>
      <Box
        data-component-id="navItem.highlight"
        as="span"
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        height="1px"
        bg="text"
        opacity={0}
      />
    </motion.div>
  </Heading>
)

function PageHeader({ workClick, children, title }) {
  useEffect(() => {
    // Set static viewport height unit on initial load
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  return (
    <Box data-component-id="pageHeader" as="header">
      <Flex
        as="section"
        display={["none", null, "flex"]}
        alignItems="center"
        height={topSpace}
        px={["layout.2", "layout.4"]}
      >
        <Flex as="nav">
          <NavItem label="Work" to="/" index={0} onClick={workClick} />
          <NavItem label="About" to="/about/" index={1} />
          <NavItem label="Contact" to="/contact/" index={2} />
        </Flex>
      </Flex>

      <Flex
        as="section"
        display={!(title || children) ? ["flex", "none"] : "flex"}
        minHeight={
          (title || children) && [
            `calc(var(--vh, 1vh) * 50)`,
            null,
            `calc((var(--vh, 1vh) * 50) - ${topSpace})`,
          ]
        }
        p={["layout.2", "layout.4"]}
        justifyContent="space-between"
      >
        <Flex
          flex={1}
          flexWrap="wrap"
          css={css({
            m: -5,
            "> *": {
              width: ["100%", null, "50%"],
              p: 5,
            },
          })}
        >
          <Box mt={[0, null, "auto"]}>
            <Flex justifyContent="space-between">
              <motion.div
                variants={HeaderFade(0.33)}
                initial="initial"
                animate="visible"
                exit="exit"
                key={"pageHeader"}
              >
                <Link
                  to="/"
                  onClick={() => {
                    if (workClick) {
                      workClick()
                    }
                  }}
                >
                  <Box
                    as="svg"
                    height={[32, 32, 40, 48]}
                    width={[
                      3.4966442953 * 32,
                      3.4966442953 * 32,
                      3.4966442953 * 40,
                      3.4966442953 * 48,
                    ]}
                    viewBox="0 0 2084 596"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit={2}
                    verticalAlign="top"
                    mb={[0, 0, 22, 14]}
                  >
                    <g fill="#000" fillRule="nonzero">
                      <path d="M356.025 199.417c0 47.233-28.233 67.783-98.733 67.783h-85.188V131.63h85.188c70.5 0 98.733 20.55 98.733 67.787zm158.563 0c0-139.063-73.308-197.792-224.113-197.792L0 1.629v594.205h172.104V389.642H282.11c138.466 0 232.479-67.167 232.479-190.225zM1295.03 270.517h-99.996v-137.6h99.996c60.071 0 74.65 36.041 74.65 71.179 0 30.217-14.579 66.42-74.65 66.42zM667.421 386.5l54.413-186.392 1.867-12.85s1.008 5.342 1.933 13.45l57.37 185.792H667.422zm864.876 175.85l-115.396-199.612c74.659-30.08 110.95-83.609 110.95-163.605 0-128.891-79.82-197.025-230.833-197.025h-269.834v593.613L808.93 3.071l-.35-.963H642.788L423.196 593.846l-.733 1.984h178.904l28.188-90.975h190.62l28.18 90.975h347.475V392.946h56.721l113.1 202.125.417.759h166.23v-33.48z" />
                      <path d="M1914.372 2.108v211.938L1720.526 2.588l-.437-.48h-206.654l215 225.317-203.725 336.271-.025-.037v32.17h170.52l146.567-249.483 72.6 75.267V595.83h168.963V2.108h-168.963z" />
                    </g>
                  </Box>
                  {/* Par
                    <Box
                      as="span"
                      display="inline-block"
                      css={{ transform: "scale(-1, 1)" }}
                    >
                      k
                    </Box> */}
                </Link>
              </motion.div>

              <Flex
                as="nav"
                display={["flex", null, "none"]}
                alignItems="flex-end"
                pb={1}
              >
                <NavItem label="Work" to="/" />
                <NavItem label="About" to="/about/" />
                <NavItem label="Contact" to="/contact/" />
              </Flex>
            </Flex>
          </Box>

          {(title || children) && (
            <Flex>
              <Box mt="auto">
                {!children && (
                  <Heading as="h2" size={900}>
                    <Box as="span" fontFamily="serif" fontWeight={300}>
                      {title}
                    </Box>
                  </Heading>
                )}
                {children}
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default PageHeader
