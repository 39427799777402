export default {
  "900": {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "32px",
    "@media screen and (min-width: 52em)": {
      fontSize: "48px",
      lineHeight: "48px",
    },
    "@media screen and (min-width: 62em)": {
      fontSize: "64px",
      lineHeight: "64px",
    },
  },
  "800": {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "32px",
    "@media screen and (min-width: 52em)": {
      fontSize: "48px",
      lineHeight: "48px",
    },
  },
  "700": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    "@media screen and (min-width: 52em)": {
      fontSize: "24px",
      lineHeight: "24px",
    },
  },
  "600": {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.008em",
    marginTop: "28px",
  },
  // Default
  "500": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "-0.006em",
    marginTop: "24px",
  },
  "400": {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.003em",
    marginTop: "16px",
  },
  "300": {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
    marginTop: "16px",
  },
  "200": {
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
    marginTop: "16px",
  },
  "100": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "16px",
  },
}
