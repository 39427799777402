import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Paragraph } from "components"

function Footer() {
  return (
    <Box data-component-id="footer" as="footer" p={["layout.2", "layout.4"]}>
      <Flex
        flexWrap="wrap"
        pb="env(safe-area-inset-bottom)"
        css={css({
          "> *": {
            width: ["100%", "50%"],
          },
        })}
      >
        <Paragraph size={300} fontFamily="sans" fontWeight={700}>
          © {new Date().getFullYear()} PARK Production House
        </Paragraph>
        <Paragraph
          size={300}
          mt={["layout.1", 0]}
          fontFamily="sans"
          fontWeight={700}
          textAlign={["left", "right"]}
        >
          <a
            href="https://builtbyfield.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Design and code by Field
          </a>
        </Paragraph>
      </Flex>
    </Box>
  )
}

export default Footer
