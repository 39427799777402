exports.Column1 = {
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.3,
    transition: {
      duration: 1,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  initial: {
    opacity: 0,
    y: 512,
    transition: {
      when: "afterChildren",
    },
  },
}

exports.Column2 = {
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.3,
    transition: { duration: 1.1, ease: [0.215, 0.61, 0.355, 1] },
  },
  initial: {
    opacity: 0,
    y: 512,
    transition: {
      when: "afterChildren",
    },
  },
}

exports.Column3 = {
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.3,
    transition: {
      duration: 1.2,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  initial: {
    opacity: 0,
    y: 512,
    transition: {
      when: "afterChildren",
    },
  },
}

exports.Column4 = {
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.3,
    transition: {
      duration: 1.4,
      ease: [0.215, 0.61, 0.355, 1],
    },
  },
  initial: {
    opacity: 0,
    y: 512,
    transition: {
      when: "afterChildren",
    },
  },
}
