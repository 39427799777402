exports.FadeIn = location => {
  return {
    initial: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        duration:
          location.pathname.indexOf("/project/") === -1 ||
          location.search.indexOf("?mobile") !== -1
            ? 0.5
            : 0,
        delay: 0.5,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration:
          location.pathname.indexOf("/project/") === -1 ||
          location.search.indexOf("?mobile") !== -1
            ? 0.5
            : 0,
        when: "afterChildren",
      },
    },
  }
}
