/*
  Utility function approach adapted from https://dev.to/joshwcomeau/persisting-react-state-in-localstorage-32p5 to fit cookies. Persists state in cookies to help make it 'sticky' across uses. Does not persist across days, so cookies are made to be temporary.

  Uses cookie functions source from W3schools - https://www.w3schools.com/js/js_cookies.asp
*/
import React from "react"

function setCookie(name, value) {
  if (typeof document !== "undefined") {
    document.cookie = name + "=" + value + ";"
  }
}

function getCookie(name) {
  if (typeof document !== "undefined") {
    var cookieName = name + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var cookieSplit = decodedCookie.split(";")
    for (var i = 0; i < cookieSplit.length; i++) {
      var c = cookieSplit[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length)
      }
    }
  }
  return ""
}

function useStickyState(defaultValue, key) {
  //on start of state, grab cookie
  const [value, setValue] = React.useState(() => {
    const cookie = getCookie(key)
    // console.log(cookie ? cookie : defaultValue)
    return cookie ? cookie : defaultValue
  })

  React.useEffect(() => {
    if (value) {
      setCookie(key, value)
    }
  }, [key, value])

  return [value, setValue]
}

export default useStickyState
