import React from "react"
import * as Reach from "@reach/accordion"
import css from "@styled-system/css"

import { Box, Column, Columns, Heading, VStack } from "components"

function Accordion({ items }) {
  return (
    <Reach.Accordion collapsible={true}>
      <VStack space="layout.3" dividers={true}>
        {items.map((item, index) => (
          <Reach.AccordionItem key={"item" + index}>
            <Columns space={["layout.4", "layout.4", "layout.6"]}>
              <Column width={[1 / 1, 1 / 2]}>
                <Box
                  as={Reach.AccordionButton}
                  ml={[20, 0]}
                  textAlign="left"
                  css={{
                    ":focus": { outline: 0 },
                    '&[aria-expanded="true"]': {
                      ".plus": {
                        "::after": {
                          transform: "translateX(-50%) scaleY(0)",
                        },
                      },
                    },
                  }}
                >
                  <Heading
                    as="span"
                    size={700}
                    position="relative"
                    mt="0.2em"
                    fontSize={24}
                    lineHeight={24 + "px"}
                    css={{ textTransform: "uppercase" }}
                  >
                    <Box
                      position="absolute"
                      top="7px"
                      left="-8px"
                      css={{ transform: "translateX(-100%)" }}
                    >
                      <Box
                        className="plus"
                        position="relative"
                        width={12}
                        height={12}
                        css={css({
                          "::before": {
                            content: "''",
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            display: "block",
                            width: "100%",
                            height: "1px",
                            bg: "text",
                            transform: "translateY(-50%)",
                          },
                          "::after": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: "50%",
                            display: "block",
                            width: "1px",
                            height: "100%",
                            bg: "text",
                            transform: "translateX(-50%)",
                            transition: "transform 200ms ease-out",
                          },
                        })}
                      />
                    </Box>
                    {item.label}
                  </Heading>
                </Box>
              </Column>
              <Column as={Reach.AccordionPanel} width={[1 / 1, 1 / 2]}>
                {item.children}
              </Column>
            </Columns>
          </Reach.AccordionItem>
        ))}
      </VStack>
    </Reach.Accordion>
  )
}

export default Accordion
