import fonts from "./fonts"

export default {
  "600": {
    fontFamily: fonts.serif,
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "20px",
    // letterSpacing: "-0.0035em",
    "@media screen and (min-width: 52em)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "500": {
    fontFamily: fonts.serif,
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "20px",
    letterSpacing: "-0.0035em",
    marginTop: 16,
  },
  // Default
  "400": {
    fontFamily: fonts.serif,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "20px",
    letterSpacing: "-0.0035em",
    marginTop: 12,
  },
  "300": {
    fontFamily: fonts.serif,
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "16px",
    letterSpacing: 0,
    marginTop: 12,
  },
}
