import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import { ThemeProvider } from "@emotion/react"
import Helmet from "react-helmet"

import { ColorMode, GlobalStyles, Theme } from "components"
import { FadeIn } from "animations"
import { useStickyState } from "utils"

import "./fonts.css"

function Layout({ location, children }) {
  const [priorPathname, setPriorPathname] = useState("")
  const [scrollStart, setScrollStart] = useStickyState("0", "scrollPosition")
  const [scrollStartTime, setScrollStartTime] = useStickyState(
    undefined,
    "scrollTime"
  )
  const [theme, setTheme] = useState("light")
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      if (width >= 832) {
        return false
      } else {
        return true
      }
    }
  })

  const checkWidth = e => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (width >= 832) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }

  useEffect(() => {
    if (location.pathname.indexOf("/project/") !== -1 && !isMobile) {
      document.documentElement.style.position = "relative"
      document.documentElement.style.height = "100%"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.position = ""
      document.documentElement.style.height = ""
      document.documentElement.style.overflow = ""
    }
  }, [location])

  useEffect(() => {
    checkWidth()
    window.addEventListener("resize", checkWidth)
    return function cleanup() {
      window.removeEventListener("resize", checkWidth)
    }
  }, [])

  // On Page Load, set scroll, and on page change, store scroll positions
  useEffect(() => {
    if (location.pathname !== "/" && priorPathname === "/") {
      setScrollStart(window.pageYOffset)
      setScrollStartTime(Date.now())
    } else {
      setTimeout(() => {
        // Check to make sure it's at home, and to make sure the timing is within the range of 15 mins, else ignore, since it's a stale cookie
        if (
          priorPathname !== "/" &&
          parseFloat(scrollStartTime) + 60 * 1000 * 15 > Date.now()
        ) {
          window.scrollTo(0, parseFloat(scrollStart))
        }
      }, 100)
    }
    setPriorPathname(location.pathname)
  }, [location])

  return (
    <ThemeProvider theme={Theme}>
      <ColorMode mode={theme}>
        <>
          <Helmet>
            <meta
              name="viewport"
              content="initial-scale=1, viewport-fit=cover"
            />
          </Helmet>
          <GlobalStyles />
          <AnimatePresence>
            <motion.div
              key={location.pathname}
              variants={FadeIn(location)}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              {React.cloneElement(children, {
                isMobile: isMobile,
                setTheme: setTheme,
              })}
            </motion.div>
          </AnimatePresence>
        </>
      </ColorMode>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
