import React, { useState } from "react"
import LastImage from "react-last-image"

function Image({ image, ...props }) {
  const [images] = useState(() => {
    if (image.url) {
      let images = []
      images.push({
        url: image.url,
        height: image.dimensions.height,
        width: image.dimensions.width,
      })
      if (image.placeholder) {
        images.push({
          url: image.placeholder.url,
          height: image.placeholder.dimensions.height,
          width: image.placeholder.dimensions.width,
        })
      }
      if (image.twoThirds) {
        images.push({
          url: image.twoThirds.url,
          height: image.twoThirds.dimensions.height,
          width: image.twoThirds.dimensions.width,
        })
      }
      if (image.half) {
        images.push({
          url: image.half.url,
          height: image.half.dimensions.height,
          width: image.half.dimensions.width,
        })
      }
      if (image.quarter) {
        images.push({
          url: image.quarter.url,
          height: image.quarter.dimensions.height,
          width: image.quarter.dimensions.width,
        })
      }
      if (image.third) {
        images.push({
          url: image.third.url,
          height: image.third.dimensions.height,
          width: image.third.dimensions.width,
        })
      }
      return images
    }
  })
  if (image.url) {
    return <LastImage images={images} alt={image.alt} {...props} />
  } else {
    return null
  }
}

export default Image
